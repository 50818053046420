 <template>
  <ion-page>
    <ion-content class="maxah-content">
      <div class="container">
        <p>Careers</p>
      </div>
      <MaxahDetailedFooter></MaxahDetailedFooter>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonContent, IonPage } from "@ionic/vue";
import MaxahDetailedFooter from '@/components/DetailedFooter.vue';

export default {
  name: "Careers",
  components: {
    IonContent,
    IonPage,
    MaxahDetailedFooter
  },
  data() {
    return {
      
    };
  },
};
</script>

<style scoped>
h1,
h3 {
  text-align: center;
  text-transform: capitalize;
}
</style>