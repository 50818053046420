
import { IonContent, IonPage } from "@ionic/vue";
import MaxahDetailedFooter from '@/components/DetailedFooter.vue';

export default {
  name: "Careers",
  components: {
    IonContent,
    IonPage,
    MaxahDetailedFooter
  },
  data() {
    return {
      
    };
  },
};
